import axios from "axios";
import {
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
} from "../reducers/types";
import { apiBaseUrl } from "../../constants/url";

export const verifyEmail = (token, userType) => async (dispatch) => {
  dispatch({ type: VERIFY_EMAIL_LOADING, payload: true });
  dispatch({ type: VERIFY_EMAIL_ERROR, payload: null });
  try {
    const response = await axios.get(
      `${apiBaseUrl}/user-auth/email-confirm/${token}/${userType}`
    );
    dispatch({ type: VERIFY_EMAIL_LOADING, payload: null });

    //   if (response.data.status !== 200) {
    //     return toast.error(response.data.message);
    //   }
    dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: response.data });
    return response.data;
  } catch (err) {
    dispatch({ type: VERIFY_EMAIL_LOADING, payload: null });
  }
};
