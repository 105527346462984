import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import store from "./redux/store";
import VerifyEmail from "./pages/VerifyEmail";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route
            path="/"
            element={
              <>
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "200px", objectFit: "16/9" }}
                    src={"./logo.svg"}
                    alt="Taski"
                  />
                </div>
              </>
            }
          />
          <Route
            path="/verify-email/:emailToken/:userType"
            element={<VerifyEmail />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
