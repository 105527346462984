import {
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_LOADING,
  VERIFY_EMAIL_SUCCESS,
} from "./types";

const initialState = {
  verifyEmailLoading: null,
  verifyEmailSuccess: null,
  verifyEmailError: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case VERIFY_EMAIL_LOADING:
      return { ...state, verifyEmailLoading: action.payload };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, verifyEmailSuccess: action.payload };
    case VERIFY_EMAIL_ERROR:
      return { ...state, verifyEmailError: action.payload };
    default:
      return state;
  }
}
