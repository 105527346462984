import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { verifyEmail } from "../redux/actions/authAction";
import { connect } from "react-redux";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const VerifyEmail = ({
  getVerifyEmailError,
  getVerifyEmailLoading,
  getVerifyEmailSuccess,
  setVerifyEmail,
}) => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params?.emailToken || !params?.userType) {
      message.error("Invalid data");
      return;
    } else {
      async function getVerifyEmailDetails() {
        try {
          const response = await setVerifyEmail(
            params?.emailToken,
            params?.userType
          );

          if (response?.status === 200) {
            message.success({
              content: "Email verification successful",
              key: "3",
            });
            navigate("/");
          } else {
            message.error({ content: "Email Verification Failed", key: "1" });
          }
        } catch (error) {
          message.error({ content: "Email Verification Failed", key: "2" });
        }
      }

      getVerifyEmailDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <img
        style={{ width: "200px", aspectRatio: "16/9" }}
        src={"/logo.svg"}
        alt="Taski"
      />
      {getVerifyEmailLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </Container>
  );
};

const mapState = (state) => ({
  getVerifyEmailLoading: state.auth.verifyEmailLoading,
  getVerifyEmailError: state.auth.verifyEmailError,
  getVerifyEmailSuccess: state.auth.verifyEmailSuccess,
});

const mapDispatch = (dispatch) => ({
  setVerifyEmail: (token, userType) => dispatch(verifyEmail(token, userType)),
});

export default connect(mapState, mapDispatch)(VerifyEmail);
