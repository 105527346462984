import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/rootReducer";
import { thunk } from "redux-thunk";

const LoggerMiddleware = createLogger();

const middleware = [];

if (process.env.REACT_APP_NODE_ENV === "development") {
  middleware.push(LoggerMiddleware);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: () => {
    return [thunk, ...middleware];
  },
});

export default store;
